import * as PropTypes from "prop-types"
import styled, { css } from "styled-components"

const defaultCss = css`
  display: block;
  margin: 0;
  letter-spacing: 0;

  b {
    display: inline-block;
  }
`

const Text = styled.p`
  ${defaultCss}
  color: ${({ textColor, theme }) => theme.colors[textColor]};
  font-weight: ${({ weight, theme }) => theme.weights[weight]};
  ${({ variant }) => styleMap[variant]}
  ${({ center }) => (center ? "text-align: center;" : null)}
  ${({ space, variant }) => (space ? spaceMap[variant] : null)}
`

export default styled(Typography)``

function Typography(props) {
  const { el, variant, children, color, ...rest } = props

  return (
    <Text as={el || variantMap[variant]} textColor={color} variant={variant} {...rest}>
      {children}
    </Text>
  )
}

Typography.defaultProps = {
  children: undefined,
  el: null,
  color: "darkBlue",
  variant: "paragraph",
  weight: "regular"
}

Typography.propTypes = {
  children: PropTypes.node,
  el: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
  weight: PropTypes.string
}

const styleMap = {
  title1: css`
    font-size: 36px;
    line-height: 40px;
  `,
  title2: css`
    font-size: 28px;
    line-height: 32px;
  `,
  title3: css`
    font-size: 21px;
    line-height: 24px;
    margin-bottom: 25px;
  `,
  title4: css`
    font-size: 18px;
    line-height: 24px;
  `,
  label: css`
    font-size: 16px;
    line-height: 21px;
  `,
  smallPrice: css`
    font-size: 13px;
    letter-spacing: -0, 2px;
    line-height: 18px;
  `,
  paragraph: css`
    font-size: 14px;
    line-height: 18px;
  `,
  small: css`
    font-size: 12px;
    line-height: 16px;
  `
}

const spaceMap = {
  title1: css`
    padding-top: 5px;
    padding-bottom: 5px;
  `,
  title2: css`
    padding-top: 9px;
    padding-bottom: 9px;
  `,
  title3: css`
    padding-top: 13px;
    padding-bottom: 13px;
  `,
  title4: css`
    padding-top: 13px;
    padding-bottom: 13px;
  `,
  label: css`
    padding-top: 14px;
    padding-bottom: 14px;
  `,
  smallPrice: css`
    padding-top: 16px;
    padding-bottom: 16px;
  `,
  paragraph: css`
    padding-top: 16px;
    padding-bottom: 16px;
  `,
  small: css`
    padding-top: 17px;
    padding-bottom: 17px;
  `
}

const variantMap = {
  title1: "h1",
  title2: "h2",
  title3: "h3",
  title4: "h4",
  label: "p",
  smallPrice: "p",
  paragraph: "p",
  small: "small"
}
