import * as PropTypes from "prop-types"
import NextLink from "next/link"
import { useLocale } from "react-polyglot-hooks"
import getPath from "helpers/getPath"

export default function Link(props) {
  const { children, page, href, params, query, ...rest } = props
  const locale = useLocale()
  const pathname = `/${locale}${page ? getPath(locale, page, params) : href}`
  return (
    <NextLink href={`${pathname}${query}`} passHref {...rest}>
      {children}
    </NextLink>
  )
}

Link.defaultProps = { page: null, href: "", params: {}, query: "" }

Link.propTypes = {
  children: PropTypes.node.isRequired,
  page: PropTypes.string,
  href: PropTypes.string,
  query: PropTypes.string,
  params: PropTypes.object
}
