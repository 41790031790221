import { createContext, useState } from "react"
import * as PropTypes from "prop-types"
import { trackBasket } from "scripts/Criteo"

const initialState = {
  show: false,
  modal: null,
  data: {}
}

export const ModalContext = createContext({
  ...initialState,
  showModal: () => null,
  hideModal: () => null
})

function ModalContextProvider({ children }) {
  const [modalData, setModalData] = useState(initialState)

  const hideModal = () => {
    setModalData(initialState)
  }

  const showModal = (modal, data) => {
    setModalData({
      show: true,
      modal,
      data
    })

    if (modal === "newOpportunity") trackBasket("formulario")
  }

  return <ModalContext.Provider value={{ ...modalData, showModal, hideModal }}>{children}</ModalContext.Provider>
}

ModalContextProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default ModalContextProvider
